@import url(theme/fonts.scss);
@import "theme/sizes.scss";
@import "theme/colors.scss";

html {
  font-size: 16px;
  @media only screen and (max-width: 440px) {
    font-size: vw-mobile(16);
  }
}

body {
  margin: 0;
  font-family: "Poppins-Regular", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@media only screen and (max-width: 440px) {
  ::-webkit-scrollbar {
    height: vw-mobile(10);
    width: vw-mobile(10);
  }
  ::-webkit-scrollbar-thumb {
    border: vw-mobile(2) solid rgba(0, 0, 0, 0);
    border-radius: vw-mobile(10);
    box-shadow: inset 0 0 0 vw-mobile(1) rgba(0, 0, 0, 0.025);
  }

  body::-webkit-scrollbar {
    height: vw-mobile(10);
    width: vw-mobile(10);
  }
  body::-webkit-scrollbar-thumb {
    border: vw-mobile(2) solid rgba(0, 0, 0, 0);
    border-radius: vw-mobile(8);
    box-shadow: inset 0 0 0 vw-mobile(1) rgba(0, 0, 0, 0.025);
  }
}
