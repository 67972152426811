@font-face {
  font-family: "Poppins-Regular";
  src: url("../../public/assets/fonts/Poppins-Regular.ttf");
}

@font-face {
  font-family: "Poppins-Medium";
  src: url("../../public/assets/fonts/Poppins-Medium.ttf");
}

@font-face {
  font-family: "Poppins-SemiBold";
  src: url("../../public/assets/fonts/Poppins-SemiBold.ttf");
}

@font-face {
  font-family: "Poppins-Bold";
  src: url("../../public/assets/fonts/Poppins-Bold.ttf");
}
