@import "theme/colors.scss";
@import "theme/sizes.scss";

.background-loader {
  position: fixed;
  height: 100vh;
  width: 100vw;
  background-color: map-get($colors, modal-page-background);
  z-index: 9999;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  .circular-wrapper {
    background-color: white;
    padding: 20px 24px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
  }
}

@media only screen and (max-width: 440px) {
  .background-loader {
    .loader-gif {
      padding: vw-mobile(20) vw-mobile(24);
      border-radius: vw-mobile(20);
      gap: vw-mobile(24);
    }
  }
}
